import { Injectable } from "@angular/core";

@Injectable()
export class UrlService {
	private isProd = true; // cambiar esta bandera false para dev y true para prod

	getApiUrl() {
		return `https://api${this.isProd ? "" : "dev"}.crediprest.mx`;
	}
}
